import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import PostListingDetailed from "src/components/generic/PostListingDetailed/PostListingDetailed";
import config from "data/SiteConfig";

const AllPostsPage = ({ data }) => {
	return (
		<Layout>
			<main>
				<Helmet title={`All Posts - ${config.siteTitle}`} />
				<div className={`m-b-xl container`}>
					<h1 className="is-size-3">All Posts</h1>
					<PostListingDetailed
						postEdges={data.allMdx.edges}
						twoColumns={true}
					/>
				</div>
			</main>
		</Layout>
	);
};

AllPostsPage.propTypes = {
	data: PropTypes.shape()
};

export default AllPostsPage;

/* eslint no-undef: "off" */
/**
 * Here we get to select the variables out of page context.
 */
export const pageQuery = graphql`
	query AllPostsPage {
		allMdx(
			limit: 1000
			sort: { fields: [fields___date], order: DESC }
			filter: { frontmatter: { draft: { eq: false } } }
		) {
			totalCount
			edges {
				node {
					fields {
						slug
						date(formatString: "MMMM DD, YYYY")
					}
					frontmatter {
						h1title
						date
						description
					}
				}
			}
		}
	}
`;
